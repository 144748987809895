import { useState, useRef, useEffect, RefObject } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NotificationsOutlined, AccountCircleRounded, Search } from '@material-ui/icons';
import { SmarterTextField } from '../Forms/Input';
import clsx from 'clsx';
import SwitchPayor from './components/SwitchPayor';
import { HeaderProps } from './interface';

export default function Header(props: HeaderProps) {
  const {
    logo,
    onMenuClick,
    onLogoClick,
    showNotification,
    onClickNotification,
    myAccountMenu,
    searchResult,
    showSearch,
    isFixedPosition,
    classes,
    payors,
    selectedPayor,
    handleChangePayor,
  } = props;

  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const [showMyAccountBox, setShowMyAccountBox] = useState<boolean>(false);
  const [activeSearchWord, setActiveSearchWord] = useState<string>('');
  const ownClasses = useStyles({ logoImage: logo });

  function useOutsideAlerter(ref: RefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMyAccountBox(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const userTyping = (e: string) => {
    setActiveSearchWord(e);
  };
  const boldString = (fString: string, bString: string) => {
    const regex = new RegExp(bString, 'g');
    return fString.replace(regex, `<b>${bString}</b>`);
  };
  const handeClickSearch = () => {
    setShowSearchResult(!showSearchResult);
    setShowMyAccountBox(false);
  };
  const handleClickMyAccount = () => {
    setShowSearchResult(false);
    setShowMyAccountBox(!showMyAccountBox);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <header
      className={
        isFixedPosition
          ? `${ownClasses.header} ${ownClasses.headerFixedPosition}`
          : `${ownClasses.header}`
      }
      ref={wrapperRef}
    >
      <div className={ownClasses.leftMenu}>
        <div className={ownClasses.burger} onClick={onMenuClick}>
          <span className={ownClasses.burgerItem} />
          <span className={ownClasses.burgerItem} />
          <span className={ownClasses.burgerItem} />
        </div>
        <div
          className={clsx({
            [ownClasses.logo]: true,
            [classes?.logo]: classes?.logo,
          })}
          onClick={onLogoClick}
        />
      </div>
      <div className={ownClasses.rightMenu}>
        <>
          {!!payors?.length && payors.length !== 1 && (
            <SwitchPayor
              payors={payors}
              // @ts-ignore
              selectedPayor={selectedPayor}
              // @ts-ignore
              onChange={handleChangePayor}
            />
          )}
          {showSearch && (
            <div className={ownClasses.menuIcons} onClick={handeClickSearch}>
              <Search />
            </div>
          )}
          {showNotification && (
            <div className={ownClasses.menuIcons} onClick={onClickNotification}>
              <NotificationsOutlined />
            </div>
          )}
          {myAccountMenu && myAccountMenu.length > 0 && (
            <>
              <div className={ownClasses.myAccountIcon} onClick={handleClickMyAccount}>
                <AccountCircleRounded />
              </div>

              {showMyAccountBox && (
                <ul className={ownClasses.myAccountMenu}>
                  {myAccountMenu &&
                    myAccountMenu.map((menu: any, index: number) => {
                      if (typeof menu.url === 'string') {
                        return (
                          <li
                            className={ownClasses.menuElement}
                            key={index}
                            onClick={() => {
                              window.location.href = menu.url;
                            }}
                          >
                            {menu.label}
                          </li>
                        );
                      } else {
                        return (
                          <li className={ownClasses.menuElement} key={index} onClick={menu.url}>
                            {menu.label}
                          </li>
                        );
                      }
                    })}
                </ul>
              )}
            </>
          )}
        </>
      </div>
      {showSearchResult && (
        <div className={ownClasses.searchBox}>
          <div className={ownClasses.searchBoxLabel}>What are you looking for?</div>
          <div>
            <div className={ownClasses.searchBoxContainer}>
              <Search className={ownClasses.searchBoxIcon} />
              <SmarterTextField onChange={userTyping} className={ownClasses.searchInput} />
            </div>
            {searchResult && (
              <ul className={ownClasses.result}>
                {searchResult.map((result: string, index: number) => {
                  return (
                    <li
                      key={index}
                      className={ownClasses.searchResultItem}
                      dangerouslySetInnerHTML={{ __html: boldString(result, activeSearchWord) }}
                    ></li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      )}
    </header>
  );
}

interface StyleProps {
  logoImage?: string;
}

const useStyles = makeStyles((theme) => ({
  burger: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logo: ({ logoImage }: StyleProps) => ({
    cursor: 'pointer',
    height: '35px',
    width: '200px',
    marginLeft: theme.spacing(2),
    backgroundImage: `url(${logoImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center left',
  }),
  burgerItem: {
    background: theme.palette.primary.main,
    display: 'block',
    marginBottom: theme.spacing(0.6),
    height: '3px',
    width: theme.spacing(3),
    borderRadius: theme.spacing(1),
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: `0px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px rgba(0, 0, 0, 0.06)`,
    padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
    background: '#fff',
    maxWidth: '100%',
  },
  headerFixedPosition: {
    position: 'fixed',
    zIndex: 999,
    top: 0,
    left: 0,
    right: 0,
  },
  leftMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  rightMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  menuIcons: {
    cursor: 'pointer',
    margin: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
  },
  myAccountIcon: {
    cursor: 'pointer',
    margin: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
    marginRight: '0',
  },
  searchBox: {
    position: 'absolute',
    left: 0,
    top: theme.spacing(10),
    width: '100%',
    background: '#fff',
    paddingBottom: 0,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
  },
  searchBoxContainer: {
    position: 'relative',
    padding: `0px ${theme.spacing(5)}`,
  },
  searchBoxIcon: {
    position: 'absolute',
    left: theme.spacing(5),
    top: theme.spacing(2),
    color: '#4AAAE9',
  },
  searchInput: {
    '& input': {
      paddingLeft: theme.spacing(5),
    },
    '& fieldset': {
      border: 'none',
    },
  },
  searchBoxLabel: {
    color: '#A6A6A6',
    fontWeight: 600,
    lineHeight: `${theme.spacing(3)}`,
    fontSize: `${theme.spacing(2)}`,
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(2),
  },
  result: {
    padding: 0,
    margin: 0,
    background: '#FAFDFF',
  },
  searchResultItem: {
    listStyleType: 'none',
    padding: `${theme.spacing(3)} ${theme.spacing(10)}`,

    '&:hover': {
      background: '#6ACCF1',
    },
  },
  myAccountMenu: {
    position: 'absolute',
    top: '75px',
    zIndex: -1,
    right: '0',
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    listStyleType: 'none',
    padding: '0',
    paddingTop: theme.spacing(0.5),
    margin: '0',
    width: theme.spacing(25),
  },

  menuElement: {
    padding: theme.spacing(1.5),
    textAlign: 'right',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
