import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stack from '../Stack';
import { PALETTE_COLORS } from '../../utils';
import React from 'react';

type SectionWithTitleProps = {
  title: string;
  description?: string | JSX.Element;
  children?: React.ReactNode;
  classes?: any;
  actions?: any;
};

export default function SectionWithTitle({
  title,
  description = '',
  actions,
  children,
  classes,
}: SectionWithTitleProps) {
  const ownClasses = useStyles();
  return (
    <Grid container className={ownClasses.root}>
      <Grid item xs={12} classes={{ root: ownClasses.sectionTitle }}>
        <div className={clsx('SectionTitleContent', classes?.title)}>
          <Stack spacing={1.3}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
            {description && (
              <Typography component="span" variant="caption">
                {description}
              </Typography>
            )}
          </Stack>
          <div>{actions}</div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider
          classes={{
            root: clsx(classes?.divider, ownClasses.divider),
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        role="sectionContent"
        classes={{
          root: clsx(classes?.sectionContent),
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#ffffff',
    padding: '28px 40px',
    borderRadius: '10px',
    marginTop: '40px',
  },
  divider: {
    backgroundColor: theme.palette.primary[PALETTE_COLORS.main],
    margin: '12px 0',
  },
  sectionTitle: {
    paddingBottom: '5px',
    '& .SectionTitleContent': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
}));
