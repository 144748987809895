import { useEffect, useState } from 'react';
import { getMenu, postLogout } from './utils';
import { useReactPath } from './useReactPath';
import { Metadata, User } from '../Utils/interfaces';

export type useMenuProps = {
  selectedPayor?: string;
};

export default function useMenu(props: useMenuProps) {
  const selectedPayor = props?.selectedPayor;
  const [headerMenu, setHeaderMenu] = useState([]);
  const [sidebarMenu, setSidebarMenu] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [roleNames, setRoleNames] = useState([]);
  const [userId, setUserId] = useState(null);
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [user, setUser] = useState<User | null>(null);

  let path = useReactPath();
  let logo = '';
  if (window?.localStorage?.getItem('logo')) {
    logo = JSON.parse(window?.localStorage?.getItem('logo') || '');
  }

  const [args, setArgs] = useState({
    sideBarProps: {},
    headerProps: {
      myAccountMenu: headerMenu,
      logo: logo,
    },
  });

  const logout = async (clientId = '') => {
    localStorage.clear();

    await postLogout({ user_id: userId }, user?.access_token);
    // if(window.location.hostname.endsWith('.fwd.com')) {
    //   window.location.href = '/uapp/auth-login';
    // }
    if (clientId) {
      window.location.href = `/uapp/${clientId}/login`;
    } else {
      window.location.href = '/uapp/login';
    }
  };

  function removeDuplicatedSubMenu(arr: any) {
    let result: any = {};
    for (let i = 0; i < arr.length; i++) {
      if (!result[arr[i].label]) {
        result[arr[i].label] = arr[i];
      }
    }
    return Object.values(result);
  }

  function removeDuplicatedMenu(arr: any) {
    let obj: any = {};
    for (let i = 0; i < arr.length; i++) {
      if (!obj[arr[i].label]) {
        obj[arr[i].label] = arr[i];
      } else {
        let mergedSubmenu = [...obj[arr[i].label].subMenu, ...arr[i].subMenu];
        let uniqeSubmenu = removeDuplicatedSubMenu(mergedSubmenu);
        obj[arr[i].label].subMenu = uniqeSubmenu;
      }
    }
    return Object.values(obj);
  }

  useEffect(() => {
    let metadata = null;
    let user = null;
    if (window?.localStorage?.getItem('metadata')) {
      metadata = JSON.parse(window?.localStorage?.getItem('metadata') || '');
    }
    if (window?.localStorage?.getItem('user')) {
      user = JSON.parse(window?.localStorage?.getItem('user') || '');
    }
    if (metadata) {
      setMetadata({ ...metadata });
      setClientId(metadata.clientId);
      setRoleNames(metadata?.roleNames);
      setUserId(metadata?.userId);
    }
    if (user) {
      setUser(user);
    }
  }, [selectedPayor]);

  useEffect(() => {
    function reOrder(arr: any) {
      // function to re-order the menu based on ordinal, and to replace %d with userId for specialist
      for (let i = 0; i < arr.length; i++) {
        let url = arr[i].url;
        if (!arr[i]?.subMenu?.length && arr[i].label !== 'Log out' && url.includes('%d')) {
          arr[i].url = arr[i].url.replace('%d', userId);
        }
        if (arr[i].subMenu) {
          for (let j = 0; j < arr[i].subMenu.length; j++) {
            let url = arr[i].subMenu[j].url;
            if (url.includes('%d')) {
              arr[i].subMenu[j].url = url.replace('%d', userId);
            }
          }
          arr[i].menus = arr[i].subMenu;
          delete arr[i].subMenu;
        }
        if (arr[i].label === 'Log out') {
          arr[i].url = () => logout(clientId || '');
        }
      }
      return arr;
    }

    async function fetchMenu() {
      let tempArrSidebarMenu: Array<object> = [];
      let tempArrHeaderMenu: Array<object> = [];
      const apiPromises = roleNames?.map((roleName: string) => getMenu(clientId || '', roleName));

      try {
        await Promise.all(apiPromises).then((values) => {
          for (let i = 0; i < values.length; i++) {
            let menuItem = values[i].data.menus.sort((a: any, b: any) => a.ordinal - b.ordinal);
            if (menuItem) {
              for (let j = 0; j < menuItem.length; j++) {
                if (menuItem[j].displayPosition === 'left') {
                  tempArrSidebarMenu.push(menuItem[j]);
                } else if (menuItem[j].displayPosition === 'right') {
                  tempArrHeaderMenu.push(...menuItem[j].subMenu);
                }
              }
            }
          }
        });
      } catch (err) {
        console.error(err);
      }
      let newTempArrHeaderMenu = removeDuplicatedMenu(tempArrHeaderMenu);
      let newTempArrSidebarMenu = removeDuplicatedMenu(tempArrSidebarMenu);
      let reOrderSidebar = reOrder(newTempArrSidebarMenu);
      let reOrderHeader = reOrder(newTempArrHeaderMenu);
      setSidebarMenu(reOrderSidebar);
      setHeaderMenu(reOrderHeader);
      setArgs({
        ...args,
        sideBarProps: {
          ...args.sideBarProps,
          menus: reOrderSidebar,
          renderLink({ url, children }: any) {
            return <a href={url}>{children}</a>;
          },
        },
        headerProps: {
          ...args.headerProps,
          myAccountMenu: reOrderHeader,
          logo: logo,
        },
      });
    }
    fetchMenu();
  }, [path, metadata]);

  return { headerMenu, sidebarMenu, args };
}
